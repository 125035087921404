#header-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
}

#header {
  display: flex;
  max-width: 1264px;
  height: 40px;
  border-bottom: 1px solid #999;
  background-color: #ccc;
  color: #333;
  margin: auto;
  width: 100%;
}
