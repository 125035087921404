html,
body,
#root {
  margin: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}
