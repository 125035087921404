#container {
  display: flex;
  max-width: 1264px;
  width: 100%;
  margin: auto;
  flex-grow: 1;
}

#content {
  width: 100%;
}
